import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import './navbar.css'

const SecondaryColor = '#03CEB9'

const NavBarContainer = styled.nav`
  width: 100%;
  position: fixed;
  font-size: 1.6rem;
  font-weight: 800;
  z-index: 100;
}
`

const Nav = styled.div`
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  justify-items: center;

  a{
    color: white;
  }

  button{
    background-color: ${SecondaryColor} ;
    border: none;
    padding: 1.5rem 2rem;
    color: white;
    font-size: 1.6rem;
    font-family: 'Raleway', 'Arial', sans-serif;
    font-weight: 700!important; 
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;

    &:hover{
      background-color: black;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      transform: translateY(-2px);
    }
  }
`

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      handleScroll : false
    } 
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 80) {
      this.setState({handleScroll: true})
    } else {
      this.setState({handleScroll: false})
    }
  }

  render() {
    return (
      <NavBarContainer className={this.state.handleScroll ? 'Nav NavScrolled': 'Nav'}>
        <Nav>
          <Link to="/"><img src={require('../images/habocic-logo.svg')} alt="Logo" width="200" /></Link>
          <Link to="/">Sobre Habocic</Link>
          <Link to="/">Servicios</Link>
          <Link to="/">Proyectos</Link>
          <Link to="/">Clientes</Link>
          <Link to="/"><button>Contáctenos</button></Link>
        </Nav>    
      </NavBarContainer>
    )
  }
}

export default NavBar
